import React from 'react'

const KnockoutForm = () => {
  return (
    <div
        className='flex items-center justify-center w-1/2 h-1/2 border-green-300 border-2 rounded'
    >KnockoutForm</div>
  )
}

export default KnockoutForm