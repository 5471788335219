import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import { listPlans } from "../actions/planActions";
import { getAboutContent } from "../actions/contentActions";

import {
  Box,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import bkgBtn from "../assets/site/play-btn.png";


function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

const Home = () => {
  const dispatch = useDispatch();
  const planList = useSelector(state => state.planList);
  const aboutContent = useSelector(state => state.aboutContent);

  // {error, loading, plans}
  const {loading, plans} = planList;
  // {contentError, contentLoading, content }
  const { contentLoading, content } = aboutContent;

  useEffect(() => {
    dispatch(listPlans());
    dispatch(getAboutContent());
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return (
    <>
    <Box
      w="100%"
    >

    <Box className="play-bkg h-96">
        <div className="flex flex-col justify-center items-center">
          <div className="w-full relative">
            <div className="w-full h-96 relative">
              <div className="cursor-pointer absolute inset-0 flex justify-center items-center">
                <Link to="/write">
                  <img
                    className="flex justify-center items-center"
                    src={bkgBtn}
                    alt="btn"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Box>

      

      <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
      >
        <div className="text-center text-2xl text-bold"><h2>About</h2></div>
        <div className="p-2 text-center">
          { contentLoading ? <div>Loading...</div> : 
            String(content).split("\n").map((str, i) => <p key={i}>{str}</p>)
          }        
        </div>
      </Box>

      <div className="relative min-w-full h-84">
        <div className="flex flex-col items-center md:flex-row md:justify-around mt-6 lg:ml-24 lg:mr-24">
          {loading ? <div>Loading...</div> : 
                      plans.map(plan => (
                        <PriceWrapper>
                        <Box 
                          py={4} 
                          px={12}
                          bg="gray.50"
                          color="gray.700"
                          _dark={{bg: "gray.900", color:"gray.200"}}
                        >
                          <Text fontWeight="500" fontSize="2xl" textAlign="center">
                            {plan.title}
                          </Text>
                          <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                              $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                              {plan.price}
                            </Text>
                            <Text fontSize="3xl" color="gray.500">
                              /month
                            </Text>
                          </HStack>
                        </Box>
                        <VStack
                          bg='gray.50'
                          _dark={{bg: "gray.900", color:"gray.200"}}
                          py={4}
                          borderBottomRadius={'xl'}>
                          <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                              <ListIcon as={FaCheckCircle} color="green.500" />
                              unlimited build minutes
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FaCheckCircle} color="green.500" />
                              Lorem, ipsum dolor.
                            </ListItem>
                            <ListItem>
                              <ListIcon as={FaCheckCircle} color="green.500" />
                              5TB Lorem, ipsum dolor.
                            </ListItem>
                          </List>
                          <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" variant="outline">
                              Start trial
                            </Button>
                          </Box>
                        </VStack>
                      </PriceWrapper>
                        // <PriceCard key={plan.id} height="300px" width="300px" name={plan.plan} tagline={plan.title} price={plan.price} description={plan.body}/>
                      ))
          }

        </div>
      </div>
     
    </Box>
    </>
    
  );
};

export default Home;