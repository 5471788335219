import React from 'react'
import SponsorSignup from './SponsorSIgnup';
import SponsorLogin from './SponsorLogin';

const SponsorsLanding = () => {
  return (
      <div>
             <h1 className="m-4 text-center text-2xl">Sign up to become a Sponsor</h1>
        <div className="m-4 text-center">

        <p>
        Sign up to become a sponsor of one (or many) of the Writers League daily comps. 
        </p>
        <p>
        The process is easy. Simply fill out the form and select the next available slot, upload your logo and select the prize amount.
        </p>
        <p>
            Once you submit the form, we'll send you an email to initiate the bank transfer. Once verified your good to go. 
        </p>
        <p>
            Welcome aboard and thanks for contributing to the Writers League community!
        </p>
        </div>
        <div className="flex flex-row">
        
        <div className="w-[50%] m-8">
            <SponsorSignup />
        </div>
        <div className="w-[50%] m-8">
            <SponsorLogin />
        </div>
      </div>
      </div>

  );
}

export default SponsorsLanding