import adjectives from './wordcloud/adjectives.json';
import nouns from './wordcloud/nouns.json';
import articles from './wordcloud/articles.json';
import conjunctions from './wordcloud/conjunctions.json';
import contractions from './wordcloud/contractions.json';
import prepositions from './wordcloud/prepositions.json';
import pronouns from './wordcloud/pronouns.json';
import verbs from './wordcloud/verbs.json';


export default function sentenceGenerator() {
    const wg_adjectives = adjectives.map(a => a.adjective);
    const wg_nouns = nouns.map(a => a.noun);
    const wg_articles = articles.map(a => a.article);
    const wg_conjunctions = conjunctions.map(a => a.conjunction);
    const wg_contractions = contractions.map(a => a.contraction);
    const wg_prepositions = prepositions.map(a => a.preposition);
    const wg_pronouns = pronouns.map(a => a.pronoun);
    const wg_verbs = verbs.map(a => a.verb);

    const joinedList = [...wg_adjectives, ...wg_nouns, ...wg_articles, ...wg_conjunctions, ...wg_contractions, ...wg_prepositions, ...wg_pronouns, ...wg_verbs ]
    let newList = joinedList.map(listItem => <div>{listItem}</div>)
    return newList;

    // let n = Math.floor(Math.random() * wg_nouns.length);
    // let art = "";
    // if(wg_nouns[n].charAt(0) !== 'a' ||
    //    wg_nouns[n].charAt(0) !== 'e' ||
    //    wg_nouns[n].charAt(0) !== 'i' ||
    //    wg_nouns[n].charAt(0) !== 'o' ||
    //    wg_nouns[n].charAt(0) !== 'u'
    // ) {
    //     art = Math.floor(Math.random() * wg_articles.length);
    // } 
       
    // let prep = Math.floor(Math.random() * wg_prepositions.length);
    // let v = Math.floor(Math.random() * wg_verbs.length);


    // let sentence = wg_articles[art] + " " + wg_nouns[n] + " " + wg_prepositions[prep] + " " + wg_verbs[v];
    // console.log(sentence)
    // return sentence
   
}

// PLACE BELOW IN REACT COMPONENT

// import sentenceGenerator from '../../data/sentenceGenerator';
  // useEffect(() => {
  //   const sentences = sentenceGenerator();
  //   console.log(sentences)

  // }, [])