import React, {useState} from 'react'

const CharacterNote = ({handleCharacterNoteData}) => {
  const [characterName, setCharacterName] = useState("")
  const [characterNoteDescription, setCharacterNoteDescription] = useState("")

  const handleNote = (e) => {
    e.preventDefault();
 
    const charDetails = {
      name: characterName,
      text: characterNoteDescription
    }
    handleCharacterNoteData(charDetails);
  }

  return (
    <div className="char-note-form w-72 h-72 m-30 top-40 z-10 absolute">
    <form onSubmit={(e) => handleNote(e)}>
      <input 
        onChange={(e) => setCharacterName(e.target.value)}
        type="text" placeholder="name" name="name"/>

      <textarea
        className='m-2'
        rows={8}
        cols={28}
        onChange={(e) => setCharacterNoteDescription(e.target.value)}
      />
      <button type="submit" value="Create Character" className="bg-red-500">Create a character</button>
    </form>
  </div>
  )
}

export default CharacterNote