import {
Box,
Container,
Stack,
Text,
useColorModeValue
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Image } from "@chakra-ui/image";
import logo from '../assets/site/logo.png';


export default function Footer() {
return (
  <Box
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}

  >
    <Container
      as={Stack}
      maxW={'6xl'}
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}>
      <Stack direction={'row'} spacing={6}>
      <Link to="/">
      <Image src={logo} ignoreFallback={true} alt="The Writers League" display={{sm: "none", md: "block",}}/>
      </Link>
        <Link href={'#'}>About</Link>
        <Link href={'#'}>Blog</Link>
        <Link href={'#'}>Contact</Link>
      </Stack>
      <Text>© 2023 The Writers League. All rights reserved</Text>
    </Container>
  </Box>
);
}