import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import Canvas from "./Canvas";

export default function Drawers({handlePageNote}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const insertPageNote = (data) => {
    handlePageNote(data);
  }

  return (
    <>
      <Box
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
        className="w-2/12 h-screen top-0 grid grid-flow-col place-items-center group/top-drawer"
      >
        {/* -left-[99%] */}
        <section className="bg-wl-yellow border-r-2 border-r-wl-oxide mt-4 absolute transition-all duration-500 -left-[99%] group-hover/top-drawer:left-0 z-30">
  
          <div className="h-screen grid place-items-center w-screen">
            <Canvas handlePageNote={insertPageNote}/>
          </div>
        </section>
      </Box>
    </>
  );
}
