import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Layout from './hocs/Layout';

import Home from './pages/Home';
import Play from './pages/Play';
import Write from './pages/Write';
import Login from './pages/Login';
import Signup from './pages/Signup';
import SponsorLogin from './pages/SponsorLogin';
import SponsorSignup from './pages/SponsorSIgnup';
import SponsorsLanding from './pages/SponsorsLanding';

import { ColorModeProvider } from '@chakra-ui/react';

//Admin
import SentenceCreator from './pages/admin/SentenceCreator';


function App() {

    return (
      <ColorModeProvider>
          <Router>
            <Layout>
          
        <main className="">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/write" element={<Write />} />
              <Route exact path="/play" element={<Play />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/landing/sponsors" element={<SponsorsLanding />} />
              <Route exact path="/login/sponsors" element={<SponsorLogin />} />
              <Route exact path="/signup/sponsors" element={<SponsorSignup />} />

              {/* ADMIN */}

              <Route exact path="/admin/sentence-creator" element={<SentenceCreator />} />

            </Routes>
            </main>

            </Layout>
          </Router>
      </ColorModeProvider>
    );
}

export default App;
