import axios from 'axios';
import {
    PLAN_LIST_REQUEST,
    PLAN_LIST_SUCCESS,
    PLAN_LIST_FAIL
} from '../constants/planConstants';

export const listPlans = () => async (dispatch) => {
    try{
        dispatch({type: PLAN_LIST_REQUEST});

        const { data } = await axios.get('https://zsb3ni.deta.dev/plans');

        dispatch({
            type: PLAN_LIST_SUCCESS,
            payload: data
        });

    }catch ( error ){
        dispatch({
            type: PLAN_LIST_FAIL,
            payload: error.response && error.response.data.message 
                    ? error.response.data.message
                    : error.message,
        });
    }
}