import { useState, useEffect } from "react";

// Scaling Constants for Canvas

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export function useWindow() {
    const [windowSize, setWindowSize] = useState(getWindowSize());


    let canvasWidth = windowSize.innerWidth * 0.96;
    let canvasHeight = windowSize.innerHeight * 0.66;

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize(getWindowSize());
        };
        window.addEventListener("resize", handleWindowResize);
      
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      });

    return [canvasWidth, canvasHeight];
}
    