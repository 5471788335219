import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate, Link } from 'react-router-dom';


import axios from 'axios';

const Signup = ({signup, isAuthenticated}) => {

  if( isAuthenticated) {
    return <Navigate to='/' />
  }
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required."),
    checkEmail: Yup.boolean().default(true),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required")
      .when("checkEmail", {
        is: true,
        then: Yup.string()
          .test({
            message: () => "Sorry, this email is taken.",
            test: async(email) => {
              if(email) {
                try {
                  let response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/users/check_email/?email=${email}`, {});
                  if (response.data === false) {
                    return true;
                  } else {
                    return false;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }
          })
      }),
      
    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password is too short"),
    re_password: Yup.string()
      .required('Confirm Password Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <figure className="h-screen flex bg-gray-100">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
      <blockquote className="text-2xl font-medium text-center">
        <p className="text-lg font-semibold">Signup</p>
      </blockquote>

      <div className="text-primary m-6">
        <div className="flex items-center mt-3 justify-center">
          <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
            Register for an account
          </h1>
        </div>
      <Formik
        initialValues={{ name: "", email: "", password: "", re_password: "", is_staff: false }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setStatus();
        //   setTimeout(() => {
        //     signup(values.email, values.name, values.password, values.re_password, values.is_staff);
        //     // setRequestSent(true);
        //     setSubmitting(false);
        //     setStatus("Success! Please check your email for the activation link.")
        //   }, 1000);
         }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.name && touched.name && (
             <div className="text-red-500">{errors.name}</div>
            )}
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.email && touched.email && (
              <div className="text-red-500">{errors.email}</div>
            )}
            <input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Password"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.password && touched.password && (
              <div className="text-red-500">{errors.password}</div>
            )}
            <input
              type="password"
              name="re_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.re_password}
              placeholder="Confirm Password"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.re_password && touched.re_password && 
              (
                <div className="text-red-500">{errors.re_password}</div>
              )
            }
            <input 
              type="checkbox"
              name="is_staff"
              checked={false}
              hidden={true}
              readOnly={true}
              value={values.is_staff}
            />
            {(<div className="text-center text-green-500 text-sm">{status}</div>)}
            <br />
            <button
              type="submit"
              disabled={isSubmitting}
              className={
                "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
              }
            >
              Submit
            </button>

            <div className="flex items-center mt-3 justify-center">
            Already have an account? &nbsp;
            <button className={"justify-center text-blue-500 hover:underline"}>
            <Link to="/login"> Login </Link>
            </button>
          </div>
          </form>  
        )}
         
      </Formik>
      
    </div>
  </div>
  </figure>
  );
}


export default Signup;
