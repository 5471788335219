import { extendTheme } from "@chakra-ui/react"



const fonts = {
    body: `'Sawasdee', sans-serif`,
    heading: `'Sawasdee', sans-serifs`
}

const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
}

const components = {
    Badge: {
      sizes: {
        sm: {
          fontSize: "10px",
        },
      },
    },
  };

const overrides = {
    fonts,
    config,
    components,
}



const customTheme = extendTheme(overrides)

export default customTheme