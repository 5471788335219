import React, { useEffect } from 'react';

import { Box, Flex, useColorModeValue } from '@chakra-ui/react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { check_authenticated, load_user } from '../actions/authActions';

const Layout = ({check_authenticated, load_user, children}) => {

  useEffect(() => {
    check_authenticated();
    load_user();
  }, [check_authenticated, load_user])


  return (

    <Flex 
      direction="column" 
      flex="1"
      bg={useColorModeValue('gray.50', 'gray.900')}
    >
      <Box pl="12" pr="12">
      <Header />
        {children}
        <Footer />
      </Box>

    </Flex>
  );
};


export default connect(null, { check_authenticated, load_user }) (Layout);
