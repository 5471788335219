import axios from 'axios';
import {
    ABOUT_CONTENT_REQUEST,
    ABOUT_CONTENT_SUCCESS,
    ABOUT_CONTENT_FAIL,
    SLIDE_CONTENT_REQUEST,
    SLIDE_CONTENT_SUCCESS,
    SLIDE_CONTENT_FAIL
} from '../constants/contentConstants';

export const getAboutContent = () => async (dispatch) => {
    try{
        dispatch({type: ABOUT_CONTENT_REQUEST});
        const { data } = await axios.get(`https://zsb3ni.deta.dev/about`);
        console.log(data);
        let content = data.content;
        dispatch({
            type: ABOUT_CONTENT_SUCCESS,
            payload: content
        });

    }catch ( error ){
        dispatch({
            type: ABOUT_CONTENT_FAIL,
            payload: error.response && error.response.data.message 
                    ? error.response.data.message
                    : error.message,
        });
    }
}


export const getSlides = () => async (dispatch) => {
    try {
        dispatch({type: SLIDE_CONTENT_REQUEST});

        const {data} = await axios.get(`https://zsb3ni.deta.dev/slides`)

        const slides = data.slides;

        dispatch({
            type: SLIDE_CONTENT_SUCCESS,
            payload: slides
        });

    } catch (error) {
        dispatch({
            type: SLIDE_CONTENT_FAIL,
            payload: error.response && error.response.data.message 
            ? error.response.data.message
            : error.message,
        });
    }
}