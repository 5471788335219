import { legacy_createStore as createStore, combineReducers , applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { planListReducer } from './reducers/planReducers';
import { aboutContentReducer, slideContentReducer } from './reducers/contentReducers';
import { canvasPlotLineCreateReducer, canvasPlotLineListReducer } from './reducers/canvasReducers';
 
const reducer = combineReducers({
    planList: planListReducer,
    aboutContent: aboutContentReducer,
    slides: slideContentReducer,
    createPlotLines: canvasPlotLineCreateReducer,
    listPlotLines: canvasPlotLineListReducer,
})
const initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;