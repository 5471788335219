import React, { useState, useEffect } from "react";

import sentenceGenerator from "../../data/sentenceGenerator";

// imports all from data/wordcloud  -- moving to server 
// user types sentence.
// sentence is broken into words
// words are checked against word cloud lists
// if word is in list then pos is assigned
// if word is not in list user adds word through second input - word is then added to list in {"pos": "word"} format eg { "noun": "dog" } => nouns.json
// user adds clause type
// json is generated

// {
//     "id": 8,
//     "sentence": "The fox is on the _____.",
//     "sentence-type": "simple",
//     "clauses": {
//       "type": "independent clause",
//       "description": "An independent clause has a subject and a verb. An independent clause expresses a complete thought."
//     },
//     "wordlist": [
//       {
//         "pos": "article",
//         "pos_description": "An article modifies a noun like an adjective does and are considered necessary to provide proper syntax to a sentence.",
//         "colour": "21 128 61",
//         "word": "the"
//       }, ... more worldlist objects

//     ]
//   }




const SentenceCreator = () => {
  const [wordList, setWordList] = useState([]);

  const [sentence, setSentence] = useState("");
  // words to add to the parts of sentence master lists
  const [wordsToAdd, setWordsToAdd] = useState([]);
  const [showWordTypeForm, setShowWordTypeForm] = useState(false);
  // word currently seeking to be 'typed' i.e. is a noun, verb etc.
  const [currentWord, setCurrentWord] = useState("")

  const pos_options = ['noun', 'verb', 'adjective', 'preposition', 'conjunction', 'contraction', 'adverb', 'pronoun', 'article']
  const clause_options = ['independent', 'dependent']
  const sentence_structure_options = ['simple', 'compound', 'complex', 'compound_complex']

  useEffect(() => {
    const wordsFromFiles = sentenceGenerator();
    console.log(wordsFromFiles)
    setWordList(wordsFromFiles);
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    const sentenceArr = sentence.split(" ");

    // check for words not in wordcloud lists - filter wordlist on elements in sentence. If not found return -1
    let words = sentenceArr.filter( el => wordList.indexOf(el)===-1);
    words.map(word => setWordsToAdd(wordsToAdd => [...wordsToAdd, word]));

  }

  const addWord = (word) => {
    setCurrentWord(word);
    setShowWordTypeForm(true);
  }

 
  const handlePosChange = (e) => {
    e.preventDefault();
    console.log(currentWord);
    console.log(e.target.value);
  }

  const handleClauseChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
  }

  return (
    <div className="w-full">
      <h1 className="text-center m-2">Slide Creator</h1>

        <div className="bg-green-200">
      Enter your sentence
      <form onSubmit={handleSubmit}>
      <input
        type="text"
        className="w-1/2 border-2"
        placeholder="Enter sentence"
        name="sentence_creator"
        onChange={(e) => setSentence(e.target.value)}
        value={sentence}
      />
      <button type="submit">Submit</button>
      </form>
      These word(s)are currently not in the master list. Would you like to add them? <br />

        <table className="table-fixed ml-[10%] w-[80%]">
          <tbody>
      {wordsToAdd.map((word, i) => (
        <tr key={i}>
         <td className="" key={i}>{word}</td>
         <td><button className="h-8 w-24 ml-8 bg-zinc-600 text-white" onClick={() => addWord({word})}>Yes?</button></td>
         <td>
         <div>
        {showWordTypeForm ?
        <span>
            Is this a?
            <select className="mx-8" onChange={handlePosChange}>
              {
                pos_options.map((option, i) => (
                  <option key={i} value={option}>{option}</option>
                ))
              }
            </select>

        </span>
        : 
        <div></div>
        }
      </div>
         </td>
        </tr>
      ))}
      </tbody>
      </table>
        <div>
          <select onChange={handleClauseChange}>
          {
            clause_options.map((option, i)=> (
              <option key={i} value={option}>{option}</option>
            ))
          }
          </select>
        </div>
        <div>
          <select onChange={handleClauseChange}>
          {
            sentence_structure_options.map((option, i)=> (
              <option key={i} value={option}>{option}</option>
            ))
          }
          </select>
        </div>

      </div>
     {wordList}
        </div>

  );
};

export default SentenceCreator;
