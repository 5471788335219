import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { useWindow } from "./hooks/useWindow";
import { useCanvasNode } from "./hooks/useCanvasNode";
import RangeSlider from "./RangeSlider";
import { SketchPicker } from "react-color";
import CharacterNote from "./CharacterNote";
import SceneNote from "./SceneNote";
import GeneralNote from "./GeneralNote";

import {useDispatch} from "react-redux";

import { createPlotLine } from '../actions/canvasActions';

const Canvas = ({handlePageNote}) => {
  let dispatch = useDispatch();

  const [updateNodeId, setUpdateNodeId] = useState({nodeId: 0, lineId: 0});
  const [showContext, setShowContext] = useState(false);
  const [ menuPos, setMenuPos] = useState({x: 0, y: 0});

  const [nodeSize, setNodeSize] = useState(10);
  const [updateNodeSize, setUpdateNodeSize] = useState(false);

  const [pickerColor, setPickerColor] = useState("#ff0000");
  const [showPicker, setShowPicker] = useState(false);
  let [nodeId, setNodeId] = useState(0);
  let [lineId, setLineId] = useState(0);

  const [canvasWidth, canvasHeight] = useWindow();
  const [nodes, setNodes, setUpdateNodes,lines, setLines, canvasRef] = useCanvasNode();

  // const [lines, setLines] = useState([]);

  const canvasEl = document.querySelector('#canvas');

  const [showCharacterNote, setShowCharacterNote] = useState(false);
  const [showSceneNote, setShowSceneNote] = useState(false);
  const [showGeneralNote, setShowGeneralNote] = useState(false);


  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  /**
   * gets current mouse pos
   * @param {window} rect 
   * @param {canvas element} canvas 
   * @param {event} evt 
   * @returns 
   */

  const getMousePos = (rect, canvas, evt) => {
    return {
        x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
        y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
    };
  }

  /**
   * draws rectangle around object for hit testing
   * @param {obj} node 
   * @returns 
   */
  const getBounds = (node) => { 
      return {
        x: node.x - node.size,
        y: node.y - node.size,
        width: node.size * 2,
        height: node.size * 2
      }  
  }


  /**
   * passes in x and y from getBounds method
   * @param {obj} rect - rectangle drawn around object. Example usage would have width, height or size properties.
   * @param {int} x - x pos of mouse
   * @param {int} y - y pos of mouse
   * @returns x, y
   */


  const containsPoint = (rect, x, y) => {
    return !(x < rect.x || x > rect.x + rect.width ||
             y < rect.y || y > rect.y + rect.height);
  }


  // when left mouse btn is down, the 'start' action begins a timer to work out if the 
  // event is a click or a drag. If the amount of time is greater than 10ms, it's a drag
  // event. A drag event sets the dragging state variable to true
  const [isDown, setIsDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [wasDragging, setWasDragging] = useState(false);
  const count = useRef(null);

  useEffect(() => {
      let timer = 0;
      if(isDown){
        count.current = setInterval(() => {
          timer += 1;
          // if after 50ms the mouse btn is still down
          // the user is dragging
          if(timer > 20) { 
            setDragging(true);
            setWasDragging(true);
          }

        }, 10);
      } else {
        setDragging(false);

      }
    return () => {
      clearInterval(count.current);
      setUpdateNodeSize(false)
    }
  }, [isDown, dragging])

  
  const handleMouseUp = (event) => {
    //timer stop
    setIsDown(!isDown);

    if(!wasDragging) {
      handleCanvasClick(event)
    }
    setWasDragging(false);
  }
 
  const handleMouseDown = (event) => {
    //timer start
    setIsDown(true);
  }

  const handleMouseMove = (event, action) => {
    if(action === "not active") {
      return;
    } else {
      let rect = canvasEl.getBoundingClientRect();
      let mouse = getMousePos(rect, canvasEl, event);

      lines.forEach(line => {
        let nodes = line[0].nodes;
        nodes.forEach(node => {
          if(containsPoint(getBounds(node), mouse.x, mouse.y)){
            
            //TODO: need to work out if one node is hitting another

             node.x = mouse.x;
             node.y = mouse.y;
 
             setUpdateNodes([
               ...nodes,
               {
                 id: nodeId,
                 x: mouse.x,
                 y: mouse.y,
                 color: pickerColor,
                 size: nodeSize,
                 action: 'updating'
               },
             ]);
          }
        })
      })
    }
  }

  const handleCanvasClick = (event) => {

    let rect = canvasEl.getBoundingClientRect();
    let coords = getMousePos(rect, canvasEl, event);
    if (event.nativeEvent.button === 2) {
      lines.forEach(line => {
        let nodes = line[0].nodes;
      nodes.forEach((node) => {
        if (containsPoint(getBounds(node), coords.x, coords.y)) {
          setUpdateNodeId({nodeId: node.id, lineId: line[0].id});
          setMenuPos({ x: coords.x, y: coords.y });
          setShowContext(true);
        }
      });
    });
    } else {

      if(showContext){
        setShowContext(!showContext);
        setUpdateNodeSize(false);
        return;
      }
      setNodes([
        ...nodes,
        {
          id: nodeId,
          x: coords.x,
          y: coords.y,
          color: pickerColor,
          size: nodeSize,
          action: "drawing",
        },
      ]);
      setNodeId((nodeId = nodeId + 1));
    }
  };

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
    }, [] );

  const handleClearCurrent = (event) => {
    setNodes([]);
    setNodeId(0);
  };

  const handleClearAll = (event) => {
    setLines([]);
    setNodes([]);
    localStorage.removeItem('wl_plotLines');
    setNodeId(0);
  };

  const handleUndoNode = (event) => {
    try {
      setNodes(nodes.slice(0, -1))
      setNodeId(nodeId - 1);
    } catch(err) {
      console.log(err)
    }
  }

  const handleDeleteNode = (event, id) => {
    try {
      console.log('delete node: ', id)
      // setNodes(nodes.slice(0, -1))
      // setNodeId(nodeId - 1);
    } catch(err) {
      console.log(err)
    }
  }


  // add lines to localStorage /
  useEffect(() => {
      dispatch(createPlotLine(lines))
     // dispatch(listPlotLines());
  },[dispatch, lines]);

// add context menu
  useEffect(() => {
      document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  const handleEndLine = () => {
    
    setLineId(lineId = lineId + 1);
    let plotLine = [{
      id: lineId,
      name: `line${lineId}`,
      nodes: nodes,
    }];
    
    if(plotLine[0].nodes.length === 0){
      return;
    }

    try {
      setLines(lines => [...lines, plotLine])
      setNodes([]);
      setNodeId(0);

    } catch (error) {
      console.log('ERR: ', error)
    }
  }

  const handleColorChange = (color) => {
        setPickerColor(color.hex);
  };


  const handleUpdateNodeSize = (e) => {
    e.preventDefault();
    setUpdateNodeSize(!updateNodeSize);
    setShowContext(false);
     if(!updateNodeSize){
        lines.forEach((line) => {
          if (line[0].id === updateNodeId.lineId) {
            let nodes = line[0].nodes;
            nodes.forEach((node) => {
              if (node.id === updateNodeId.nodeId) {
                 node.size = nodeSize;
                setUpdateNodes([
                  ...nodes,
                  {
                    id: nodeId,
                    x: node.x,
                    y: node.y,
                    color: pickerColor,
                    size: node.size,
                    action: "updating",
                  },
                ]);
              }
            });
          }

        });
       
      } else {
        setUpdateNodeId({nodeId: null, lineId: null});
      }

  }

  const handleSliderChange = useCallback(
 
    (val) => {
      setNodeSize(val);
    },
    [setNodeSize]
  );

  const sliderProps = useMemo(
    () => ({
      min: 10,
      max: 50,
      value: nodeSize,
      step: 2,
      label: "Size",
      onChange: (e) => handleSliderChange(e),
    }),
    [nodeSize, handleSliderChange]
  );

  const [charFormNoteData, setCharFormNoteData] = useState(null);
  const [sceneFormNoteData, setSceneFormNoteData] = useState(null);
  const [generalFormNoteData, setGeneralFormNoteData] = useState(null);

  const [nodeNotes, setNodeNotes] = useState([])

  const handleShowCharacterNote = (event) => {
    event.preventDefault();
    setShowCharacterNote(!showCharacterNote);
    if(!showCharacterNote && showContext){
      setShowContext(false);
    }
   };

   const characterNoteData = (charFormNoteData) => {
      setCharFormNoteData(charFormNoteData);
      lines.forEach((line) => {
        if (line[0].id === updateNodeId.lineId) {
          let nodes = line[0].nodes;
          
          nodes.forEach((node) => {
            if (node.id === updateNodeId.nodeId) {
              if(nodeNotes.length > 0){
                node.notes = nodeNotes;
              } else {
                node.notes = [{
                  "id": 0,
                  "type": "Character",
                  "name": charFormNoteData.name,
                  "text": charFormNoteData.text
                }]
              }


              setUpdateNodes([
                ...nodes,
                {
                  id: nodeId,
                  x: node.x,
                  y: node.y,
                  color: pickerColor,
                  size: node.size,
                  notes: node.notes,
                  action: "updating",
                },
              ]);

              setNodeNotes([
                ...nodeNotes,
                {
                  "id": 0,
                  "type": "Character",
                  "name": charFormNoteData.name,
                  "text": charFormNoteData.text
                }
              ]);
              const storyLength = 500;
              const numWordsPerLine = 20;
            
              let lineNum = Math.floor((Math.floor(canvasWidth / storyLength) * Math.floor(node.x) * .1) / numWordsPerLine);

              const insertNoteDetails = { 
                "line": lineNum,
                "text": charFormNoteData.text
              }
              handlePageNote(insertNoteDetails)
            }
          });
          
        }
      });

   }

  const handleShowSceneNote = (event) => { 
    event.preventDefault();
    setShowSceneNote(!showSceneNote);
    if(!showSceneNote && showContext){
      setShowContext(false);
    }    
  };

  const sceneNoteData = (sceneFormNoteData) => {
    setSceneFormNoteData(sceneFormNoteData);
    lines.forEach((line) => {
      if (line[0].id === updateNodeId.lineId) {
        let nodes = line[0].nodes;
        
        nodes.forEach((node) => {
          if (node.id === updateNodeId.nodeId) {
   
            if(nodeNotes.length > 0){
              node.notes = nodeNotes;
            } else {
              node.notes = [{
                "id": 0,
                "type": "Scene",
                "name": sceneFormNoteData.name
              }]
            }

            setUpdateNodes([
              ...nodes,
              {
                id: nodeId,
                x: node.x,
                y: node.y,
                color: pickerColor,
                size: node.size,
                notes: node.notes,
                action: "updating",
              },
            ]);


            console.log(nodes)

            setNodeNotes([
              ...nodeNotes,
              {
                "id": 0,
                "type": "Scene",
                "name": sceneFormNoteData.name
              }
            ]);
            console.log(nodeNotes)
          }
        });
      }

    });
  }

  const handleShowGeneralNote = (event) => { 
    event.preventDefault();
    setShowGeneralNote(!showGeneralNote);
    if(!showGeneralNote && showContext){
      setShowContext(false);
    }
  };

  const generalNoteData = (generalFormNoteData) => {
    setGeneralFormNoteData(generalFormNoteData);
    console.log(generalFormNoteData)
    lines.forEach((line) => {
      if (line[0].id === updateNodeId.lineId) {
        let nodes = line[0].nodes;
        
        nodes.forEach((node) => {
          if (node.id === updateNodeId.nodeId) {
            if(nodeNotes.length > 0){
              node.notes = nodeNotes;
            } else {
              node.notes = [{
                "id": 0,
                "type": "General",
                "name": generalFormNoteData.name,
              }]
            }


            setUpdateNodes([
              ...nodes,
              {
                id: nodeId,
                x: node.x,
                y: node.y,
                color: pickerColor,
                size: node.size,
                notes: node.notes,
                action: "updating",
              },
            ]);


            console.log(nodes)

            setNodeNotes([
              ...nodeNotes,
              {
                "id": 0,
                "type": "General",
                "name": generalFormNoteData.name,
              }
            ]);
            console.log(nodeNotes)
          }
        });
      }

    });
  }

  return (
    <>
      <div className="flex flex-col items-start md:items-center">
     
        <div className="flex flex-row -mt-24">

        <div className="flex flex-row justify-content-between">
          <div className="button mr-4">
            Node Color
            {showPicker ? (
              <SketchPicker
                color={pickerColor}
                onChangeComplete={handleColorChange}
                className="absolute ml-10 z-50"
              />
            ) : (
              <div></div>
            )}
            <div
              onClick={togglePicker}
              className="w-5 h-5 p-2 m-4 outline outline-offset-2 outline-1 outline-slate-300 shadow-md"
              style={{ backgroundColor: pickerColor }}
            ></div>
          </div>
           <RangeSlider {...sliderProps} classes="w-20 mr-4 h-8 relative" />
         
            <div
              onClick={(e) => handleShowCharacterNote(e)}
              className="char-note cursor-pointer mr-8 h-20 w-20 bg-orange-500 border-orange-400 shadow-md z-100"
            >
              Character
            </div>
            {showCharacterNote ? <CharacterNote handleCharacterNoteData={characterNoteData}/> : null}
            <div
              onClick={(e) => handleShowSceneNote(e)}
              className="scene-note cursor-pointer mr-8 h-20 w-20 bg-green-500 border-green-400 shadow-md"
            >
              Scene
            </div>
            {showSceneNote ? <SceneNote handleSceneNoteData={sceneNoteData}/> : null}
            <div
              onClick={(e) => handleShowGeneralNote(e)}
              className="note cursor-pointer mr-8 mb-4 h-20 w-20 bg-yellow-500 border-yellow-400 shadow-md"
            >
              Note
            </div>
            {showGeneralNote ? <GeneralNote handleGeneralNoteData={generalNoteData}/> : null}
            <button
              onClick={handleClearCurrent}
              className="w-4/5 h-12 bg-wl-yellow rounded-md shadow-lg border-2 border-wl-oxide"
            >
              CLEAR CURRENT
            </button>
            <button
              onClick={handleClearAll}
              className="w-4/5 h-12 bg-wl-yellow rounded-md shadow-lg border-2 border-wl-oxide"
            >
              CLEAR ALL
            </button>
            <button
              onClick={handleUndoNode}
              className="w-4/5 h-12 bg-wl-yellow rounded-md shadow-lg border-2 border-wl-oxide"
            >
              UNDO
            </button>
            <button
              onClick={handleEndLine}
              className="w-4/5 h-12 bg-wl-yellow rounded-md shadow-lg border-2 border-wl-oxide"
            >
              END LINE
            </button>
          </div>
        </div>
       
        <div className="flex flex-row justify-content-center m-2">
        {showContext ? (
              <ul
              className="bg-white w-42 h-42 absolute z-50 p-2 border-2"
              style={{
                top: menuPos.y + 120,
                left: menuPos.x,
              }}
            >
              <li 
                className="cursor-pointer"
                onClick={(e) => handleUpdateNodeSize(e, updateNodeId)}
              >
                Change Size
              </li>          
              <li 
                className="cursor-pointer"
                onClick={(e) => handleShowCharacterNote(e) }
              >
                Add Character Note
              </li>
              <li 
                className="cursor-pointer"
                onClick={(e) => handleShowSceneNote(e)}
              >
                Add Scene Note
              </li>
              <li 
                className="cursor-pointer"
                onClick={(e) => handleShowGeneralNote(e)}
              >
                Add General Note
              </li>
              <hr 
                className="divider mt-2 mb-2" />
              <li 
                className="cursor-pointer"
                onClick={(e) => handleDeleteNode(e, updateNodeId)}
              >
                Delete
              </li>
            </ul>
      ) : (
        <> </>
      )}
          <canvas
            id="canvas"
            className="relative border shadow-inner shadow-md bg-amber-100 absolute z-1"
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
            // onClick={handleCanvasClick}
            onMouseMove={!dragging ? (e) => handleMouseMove(e, "not active") : (e) => handleMouseMove(e, "active")}
            onMouseDown={(e) => handleMouseDown(e)}
            onMouseUp={(e) => handleMouseUp(e)}
          />
        </div>
      </div>
    </>
  );
};



export default Canvas;
