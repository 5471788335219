import React, {useState} from 'react'
import { Box, Flex } from '@chakra-ui/react';
import Drawers from '../components/Drawers';
import WLEditor from '../components/editor/WLEditor';


const Write = () => {

  const notes = [
    {
      "line": 15,
      "color": "red",
      "note": "The man went across the street"
    },
    {
      "line": 5,
      "color": "red",
      "note": "The man went across the street"
    }
  ];

  const [lineNote, setLineNote] = useState([])

  const insertPageNote = (data) => {
    console.log('line',lineNote, data)

    setLineNote([
      ...lineNote,
      {
        "id": 0,
        "type": "Character",
        "line": data.line,
        "text": data.text
      }
    ]);
  }
  

  return (
    <Flex
     direction="row"
    >
      <Box
        display="flex"
      >
        <WLEditor
         notes={lineNote}
      />
      <Drawers 
        handlePageNote={insertPageNote}
      />
      </Box>

    </Flex>
  );
}

export default Write