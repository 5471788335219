import {
    PLOTLINE_CREATE_SUCCESS,
    PLOTLINE_CREATE_FAIL,
    PLOTLINE_LIST_SUCCESS,
    PLOTLINE_LIST_FAIL
} from '../constants/canvasConstants';

export const canvasPlotLineCreateReducer = (state ={plotLines:[]}, action) => {
    switch (action.type) {

        case PLOTLINE_CREATE_SUCCESS:
            return {loading: false, plotLines: action.payload}
        case PLOTLINE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const canvasPlotLineListReducer = (state ={plotLines:[]}, action) => {
    switch (action.type) {

        case PLOTLINE_LIST_SUCCESS:
            return {loading: false, plotLines: action.payload}
        case PLOTLINE_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

