import {  
    PLOTLINE_CREATE_SUCCESS,
    PLOTLINE_CREATE_FAIL,
    PLOTLINE_LIST_SUCCESS,
    PLOTLINE_LIST_FAIL
} from '../constants/canvasConstants';



export const createPlotLine = (plotLines) => async dispatch => {

    const wlStorage = localStorage.getItem('wl_plotLines');
    if(wlStorage){
        localStorage.removeItem('wl_plotLines');     
        //plotLines comes in as an array
        localStorage.setItem('wl_plotLines', JSON.stringify(plotLines))
    } else {
        JSON.stringify(localStorage.setItem('wl_plotLines', JSON.stringify(plotLines)))
    }
    
    const body = JSON.stringify(plotLines);

    try {
        dispatch({
            type: PLOTLINE_CREATE_SUCCESS,
            payload: body
        });

    } catch(err) {
        dispatch({
            type: PLOTLINE_CREATE_FAIL,
            payload: err.response.data
        });
    }
};


export const listPlotLines = () => async dispatch => {

    const plotLines = JSON.parse(localStorage.getItem('wl_plotLines'))

    try {

        dispatch({
            type: PLOTLINE_LIST_SUCCESS,
            payload: plotLines
        });

    } catch(err) {
        dispatch({
            type: PLOTLINE_LIST_FAIL,
            payload: err
        });
    }
};