import {
    ABOUT_CONTENT_REQUEST,
    ABOUT_CONTENT_SUCCESS,
    ABOUT_CONTENT_FAIL,
    SLIDE_CONTENT_REQUEST,
    SLIDE_CONTENT_SUCCESS,
    SLIDE_CONTENT_FAIL
} from '../constants/contentConstants';

export const aboutContentReducer = (state ={content:[]}, action) => {
    switch (action.type) {
        case ABOUT_CONTENT_REQUEST:
            return {contentLoading: true, content: []}
        case ABOUT_CONTENT_SUCCESS:
            return {contentLoading: false, content: action.payload}
        case ABOUT_CONTENT_FAIL:
            return {contentLoading: false, contentError: action.payload}
        default:
            return state;
    }
}

export const slideContentReducer = (state ={slides:[]}, action) => {
    switch (action.type) {
        case SLIDE_CONTENT_REQUEST:
            return {slidesLoading: true, slides: []}
        case SLIDE_CONTENT_SUCCESS:
            return {slidesLoading: false, slides: action.payload}
        case SLIDE_CONTENT_FAIL:
            return {slidesLoading: false, slides: action.payload}
        default:
            return state;
    }
}
