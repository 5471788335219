import { useState, useEffect, useRef } from "react";
import {useWindow} from "./useWindow";


export function addNodeConnector(ctx, color, mx, my, lx, ly) {
  // push nodes to line array
  // add line
  
   ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(mx, my);
    ctx.lineTo(lx, ly);
    ctx.stroke();
   
}

export function drawNode(ctx, id, x, y, color, nodeSize) {

  ctx.font = "12px Arial";

  ctx.fillStyle = color;
  ctx.strokeStyle = color + "33";
  ctx.lineWidth = 4
  ctx.beginPath();
  ctx.arc(x, y, nodeSize, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.stroke();
  ctx.fill();
  ctx.restore();

  ctx.fillText(id + 1, x + nodeSize + 10, y - nodeSize - 5);
}

export function clearCanvas(canvasRef, width, height) {
    const canvasObj = canvasRef.current;
    const ctx = canvasObj.getContext("2d");
    // clear the canvas area before rendering the coordinates held in state
    ctx.clearRect(0, 0, width, height);
    return ctx;
}

export function renderLines(ctx, lines) {

    try{
      if (lines) {
        ctx.font = "12px Arial";
        // loop through lines
        for (let i = 0; i < lines.length; i++) {
          // add name to plot line

         ctx.fillText(lines[i][0].name, lines[i][0].nodes[0].x - lines[i][0].nodes[0].size - 20, lines[i][0].nodes[0].y - 20);
          //defer to renderNodes
          let nodes = lines[i][0].nodes;
          renderNodes(ctx, nodes)
        }
      }
    } catch(err) {
      console.log(err);
    }
}

export function renderNodes(ctx, nodes) {
  try {
    nodes.forEach((node) => {
      if (node.action === "drawing") {
        let x = node.x;
        let y = node.y;
        if(node.id === 0){
          drawNode(ctx, node.id, x, y, node.color, node.size);
          return;
        }
        if(node.id >= 1) {

          addNodeConnector(
            ctx,
            nodes[node.id - 1].color,
            nodes[node.id - 1].x,
            nodes[node.id - 1].y,
            nodes[node.id].x,
            nodes[node.id].y
          );
          drawNode(ctx, node.id, x, y, node.color, node.size);
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].action === "updating") {
      ctx.beginPath();
      ctx.arc(nodes[i].x, nodes[i].y, nodes[i].size, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
      ctx.restore();
    }
  }
}



export function useCanvasNode() {
  // let lines = useSelector(state => state.listPlotLines.plotLines);

  const canvasRef = useRef(null);

  const [nodes, setNodes] = useState([]);
  const [updateNodes, setUpdateNodes] = useState(...nodes);

  const [lines, setLines] = useState([]);
 
  let [width, height] = useWindow();

  useEffect(() => {
    let clear = true;
    let ctx = {};
    if(clear) {
      ctx = clearCanvas(canvasRef, width, height);
      renderLines(ctx, lines); 
      renderNodes(ctx, nodes);
    }
    return () => {
      clear = false;
    }
  });


  return [nodes, setNodes, setUpdateNodes, lines, setLines, canvasRef];
}
