import React, {useState} from 'react';

const SceneNote = ({handleSceneNoteData}) => {
  const [sceneName, setSceneName] = useState("");

  const handleNote = (e) => {
    e.preventDefault();

    const sceneDetails = {
      name: sceneName
    }
    handleSceneNoteData(sceneDetails)
  }

  return (
    <div className="scene-note-form w-72 h-72 m-30 top-40 z-10 absolute">
    <form onSubmit={(e) => handleNote(e)}>
      <input onChange={(e) => setSceneName(e.target.value)} type="text" placeholder="name" name="name"/>
      <button type="submit" value="Create Scene" className="bg-red-500">Create a Scene</button>
    </form>
  </div>
  )
}

export default SceneNote