import React, {useState} from 'react'

const GeneralNote = ({handleGeneralNoteData}) => {
  const [noteName, setNoteName] = useState("")

  const handleNote = (e) => {
    e.preventDefault();

    const noteDetails = {
      name: noteName
    }

    handleGeneralNoteData(noteDetails)
  }


  return (
    <div className="note-form w-72 h-72 m-30 top-40 z-10 absolute">
      <form onSubmit={(e) => handleNote(e)}>
        <input
          onChange={(e) => setNoteName(e.target.value)}
          type="text"
          placeholder="name"
          name="name"
        />
        <button type="submit" value="Create Note" className="bg-red-500">
          Create a note
        </button>
      </form>
    </div>
  );
}

export default GeneralNote