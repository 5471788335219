import React, { useCallback } from "react";
import { Box } from "@chakra-ui/react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./styles.css";


var Delta = Quill.import('delta');

class Counter {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.container = document.querySelector(options.container);
    quill.on('text-change', this.update.bind(this));
    this.update();
  }

  calculate() {
    let text = this.quill.getText();
    text = text.trim();
    let wordCount = text.split(/\s+/).length;

    return text.length > 0 ? wordCount : 0;
  }

  update() {
    let length = this.calculate();
    let label = "word";
    if(length > 1){
      label += "s";
    }
    this.container.innerText = length + " " + label;
  }
}




const TOOLBAR_OPTIONS = [
  [{header: [1,2,3,4,5,6,false]}],
  [{font:[]}],
  [{list: "ordered"}, {list: "bullet"}],
  ["bold", "italic", "underline"],
  [{color: []}, {background: []}],
  [{script: "sub"}, {script: "super"}],
  [{align: []}],
  ["image", "blockquote", "code-block"],   
  ["clean"],
]

const WLEditor = ({notes}) => { 

  const wrapperRef = useCallback((wrapper) => {
    if(wrapper === null ) return;

    wrapper.innerHTML = "";
    const editor = document.createElement('div');
    wrapper.append(editor);

    Quill.register('modules/counter', Counter);

    const quill = new Quill(
      editor, 
      { 
        theme: "snow", modules: { 
        toolbar: TOOLBAR_OPTIONS, 
        counter: {
          container: '.counter'
        }
      
      }, 
      scrollingContainer: '#scrolling-container',
      placeholder: '                       The journey begins here...'
    });


    const generateLines = (num) => {
      
      let lines = "";
    for(let i = 0; i < num; i++){
      lines += "\n";
    }
      return lines;
    }



    try {
      if(notes){
      
      notes.reverse().forEach(note => {
        console.log(note);
        quill.updateContents(new Delta()
        .insert(generateLines(note.line))
        .insert(note.text)
      );
      });

      }
    } catch (error) {
      console.log(error)
    }
  }, [notes]);


 

  return (
    <>
    <div id="scrolling-container">
    <span className="counter"></span>
      <Box className="container" ref={wrapperRef}>
      </Box>
    </div>

      
    </>

  )
}

// setting, scene, character, plot

export default WLEditor