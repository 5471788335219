import React, {useState} from "react";

import roundOne from "../data/knockout/firstRound.json";
import roundTwo from "../data/knockout/secondRound.json";
import roundThree from "../data/knockout/thirdRound.json";
import finalRound from "../data/knockout/finalRound.json";
import KnockoutForm from "./KnockoutForm";


const Knockout = () => {
    let [showEntry, setShowEntry] = useState(false);
    let [groupData, setGroupData] = useState({});
    const [canEnter, showCanEnter] = useState(true);

    const [showRules, setShowRules] = useState(false);

  
    const handleGroupOver = (entry) => {
        setShowEntry(true)
        setGroupData(entry)
    }

    const handleGroupOut = () => {
        setShowEntry(false);
    }

    const handleGroupClick = () => {
      return canEnter ? <KnockoutForm /> : console.log('cant enter');
    }


  return (
    <div className="h-96 mb-8 border-b-4 border-wl-oxide">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col w-[100%] md:w-[75%] h-96">

          <h1 className="mt-4 text-3xl text-black text-center">Knockout!</h1>
  

          <div className="-ml-[7%]">
            <div className="grid grid-cols-1 ml-[50%]">
              {finalRound.map((entry, index) => (
                <div
                  onMouseOver={(e) => handleGroupOver(entry)}
                  onMouseOut={(e) => handleGroupOut()}
                  className="cursor-pointer flex w-16 h-16 border-[1px] border-wl-oxide rounded border-4 justify-center items-center text-xl"
                >
                  <div>
                    <p className="text-sm">Group</p>
                    <p className="text-center">{index + 15}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 ml-[28%] mt-2 z-0">
              <div className="w-[126%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
            </div>
            <div className="grid grid-cols-2 ml-[27%] w-[90%]">
              {roundThree.map((entry, index) => (
                <div
                  onMouseOver={(e) => handleGroupOver(entry)}
                  onMouseOut={(e) => handleGroupOut()}
                  className="cursor-pointer flex w-16 h-16 border-[1px] border-wl-oxide rounded border-4 justify-center items-center text-xl"
                >
                  <div>
                    <p className="text-sm">Group</p>
                    <p className="text-center">{index + 13}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Second round */}
            <div className="grid grid-cols-2 ml-[17%] mt-2 z-0">
              <div className="w-[55%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
              <div className="ml-[8%] w-[55%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
            </div>
            <div className="grid grid-cols-4 ml-[16%]">
              {roundTwo.map((entry, index) => (
                <div
                  onMouseOver={(e) => handleGroupOver(entry)}
                  onMouseOut={(e) => handleGroupOut()}
                  className="cursor-pointer flex w-16 h-16 border-[1px] border-wl-oxide rounded border-4 justify-center items-center text-xl"
                >
                  <div>
                    <p className="text-sm">Group</p>
                    <p className="text-center">{index + 9}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* first round */}
            <div className="h-12">
              <div className="grid grid-cols-4 ml-[11%] mt-2 z-0">
                <div className="w-[55%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
                <div className="w-[55%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
                <div className="w-[55%] ml-[1%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
                <div className="w-[55%] ml-[2%] h-2 border-l-[1px] border-r-[1px] border-t-[1px] border-solid border-slate-400"></div>
              </div>
              <div className="grid grid-cols-8 ml-[9%] z-10">
                {roundOne.map((entry, index) => (
                  <div
                    onMouseOver={(e) => handleGroupOver(entry)}
                    onMouseOut={(e) => handleGroupOut()}
                    className="cursor-pointer flex w-16 h-16 border-[1px] border-wl-oxide rounded border-4 justify-center items-center text-xl"
                  >
                    <div>
                      <p className="text-sm">Group</p>
                      <p className="text-center">{index + 1}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-[100%] md:w-[25%] h-96 border-l-4 border-wl-oxide p-4">
          <div
            onClick={() => setShowRules(!showRules)}
            className="z-50 w-[100%] bg-slate-200 h-6 text-bold"
          >
            <div className="flex flex-row">
              <h1 className="flex w-12 p-[1px] pl-2">Rules</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="flex w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
          {showRules ? (
            <div className="">
              <ul class="list-none">
                <li>500 word story limit</li>
                <li>Completed 5pm on day of pool</li>
                <li>10c to vote</li>
                <li>10c to enter</li>
                <li>Top 4 go through to next round</li>
              </ul>
              <h3>Keep it clean people!</h3>
            </div>
          ) : (
            <div></div>
          )}

          {/* Group Results */}
          {showEntry ? (
            <div className="absolute top-20 -ml-[10px] z-50 w-[24%] h-36 bg-white">
              <div className="text-sm">
                <div className="flex justify-between">
                  <span>{groupData.date}</span>
                  <span>{groupData.poolVotes} total votes</span>
                </div>
                <div className="text-center -mb-4">Players</div> <br />
                <div>
                  {groupData.players.map((player) => (
                    <div className="grid grid-cols-2 border-b-[1px] bg-white">
                      <span>{player[0]}</span>
                      <span className="ml-24">{player[1]}</span>
                      <hr />
                    </div>
                  ))}
                </div>
                <div></div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div 
            onClick={handleGroupClick}
            class="cursor-pointer flex justify-center items-center absolute bottom-40 w-1/5 h-12 bg-wl-yellow rounded-md shadow-lg border-2 border-wl-oxide">
            Enter Knockout
          </div>
        </div>
      </div>
    </div>
  );
};

export default Knockout;
